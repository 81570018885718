import React, {
  useState,
} from 'react'

import './Compliance.css'

import Footer from '../../components/Footer/Footer'
import PageTitle from '../../components/PageTitle/PageTitle'
import StaffSignature from '../../components/StaffSignature/StaffSignature'

import CheckMark from '../../assets/svg_icons/check_mark.svg'

export default function GPP(props) {
  return (
    <div className='compliance-container'>
      <div className='compliance-body'>
        <PageTitle 
          title='Grievance Policy And Procedures'
        />  
        <div className='compliance-contents'>
          <p className='compliance-p-1'>
            This grievance procedure is designed to allow all parties to report any alleged failures by the Company to respect the Voluntary Principles of Security and Human Rights or business ethics. Personnel and third parties are encouraged to report allegations of improper and/or illegal conduct, including bullying and harassment, discrimination, violation, misconducts and such acts or omissions that would violate the principles contained in the International Code of Conduct Association (ICoCA) and company policies, procedures, rules and regulations
          </p>
          <p className='compliance-p-2'>
            Our grievance procedures are equitable, accessible, and offer effective remedies, which include recommendations for the prevention of reoccurrence.
          </p>
          <p className='compliance-p-2'>
            These Procedures also facilitate the reporting of improper or illegal conduct, or a violation of the ICoCA Code, that has occurred or is about to occur. All allegations will be investigated promptly and impartially and with due consideration to confidentiality.
          </p>
          <p className='compliance-subtitle'>
            Gelose Marine Services will:
          </p>
          <div className='compliance-checked-item'>
            <img 
              src={CheckMark}
              alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
            />
            <p>
              Maintain records about any such allegations, findings, or disciplinary measures, except where prohibited or protected by applicable law, such records would be made available to a Competent Authority on request.
            </p>
          </div>
          <div className='compliance-checked-item'>
            <img 
              src={CheckMark}
              alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
            />
            <p>
              Cooperate with official investigations, and not participate in or tolerate from her Personnel, the impeding of witnesses, testimony, or investigations.
            </p>
          </div>
          <div className='compliance-checked-item'>
            <img 
              src={CheckMark}
              alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
            />
            <p>
              Take appropriate disciplinary action, which could include termination of employment in case of a finding of such violations or unlawful behavior; and
            </p>
          </div>
          <div className='compliance-checked-item'>
            <img 
              src={CheckMark}
              alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
            />
            <p>
            Ensure that their Personnel who report wrongdoings in good faith are provided protection against any retaliation for making such reports, such as shielding them from unwarranted or otherwise inappropriate disciplinary measures, and that matters raised are examined and acted upon without undue delay.
            </p>
          </div>
          <p className='compliance-p-2'>
            All reports on violation and misconduct should be sent to <a 
            style={{
              fontWeight: 'bolder',
              color: '#fff',
            }} 
            href="mailto:hr@gelosemarine.com">hr@gelosemarine.com</a>, <a 
            style={{
              fontWeight: 'bolder',
              color: '#fff',
            }} 
            href="mailto:feedback@gelosemarine.com">feedback@gelosemarine.com</a> or call <a 
            style={{
              fontWeight: 'bolder',
              color: '#fff',
            }} 
            href="tel:+234-906 280 7051">+234-906 280 7051</a>.
          </p>
          <StaffSignature />
        </div>
        <Footer />
      </div>
    </div>
  )
}